import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  console.log("TRACE LOGIN");
  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      localStorage.clear();
    } 
    setLoading(false);
    setForgotPassword(false);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();

    const user = {
      username: username,
      password: password
    };

    const reset = {
      username: username,
    };

    console.log('forgotPassword = ' + forgotPassword);
    if (forgotPassword === true) {
      console.log("password reset required.");
      fetch(process.env.REACT_APP_API_SERVER_URL + '/api/v1/users/auth/password/reset/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reset)
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.key) {
            console.log("data.key = " + data.key);
            localStorage.clear();
            localStorage.setItem('token', data.key);
            window.location.replace(process.env.REACT_APP_SERVER_URL + '/dashboard');
          } 
          else {
            setUsername('');
            setPassword('');
            setForgotPassword(false);
            localStorage.clear();
            setErrors(true);
            setMessage(Object.values(data));
            setLoading(true);
          }
        });
    }
    else {
      try {
        const res = await axios.post(process.env.REACT_APP_API_SERVER_URL+'/auth/login', 
                                      {
                                          username,
                                          password
                                      });
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          console.log(res.data);
          // setLoggedInUser(username);
          if (res.data.groups.length > 0) {
            // setUserGroups(res.data.groups);
            if (res.data.groups.includes("admin")) {
                console.log("Admin user group.");
                // setIsAdmin(true);
            }
          }
          // window.location.replace('https://dync.org/dashboard');
          window.location.replace(process.env.REACT_APP_SERVER_URL);
        }
      }
      catch (err) {
        console.error(err.response.data);
        // Set error message
        setMessage(err.response.data.msg);         
        // setMessage('Failed to login - wrong credentials');         
      }
      
      // Set success message
      // setMessage('Logged in successfully');
      // fetch(process.env.REACT_APP_API_SERVER_URL + '/api/v1/users/auth/login/', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // })
      //   .then(res => res.json())
      //   .then(data => {
      //     console.log(data);
      //     if (data.key) {
      //       console.log("data.key = " + data.key);
      //       localStorage.clear();
      //       localStorage.setItem('token', data.key);
      //       window.location.replace(process.env.REACT_APP_SERVER_URL + '/dashboard');
      //     } 
      //     else {
      //       setUsername('');
      //       setPassword('');
      //       setForgotPassword(false);
      //       localStorage.clear();
      //       setMessage(Object.values(data));
      //       setErrors(true);
      //     }
      //   });
      }
  };

  return (
    <div className="container-fluid login">
      {(message.length > 0 || errors === true) &&
        <div className="alert alert-success alert-dismissible" role="alert">
        {message}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      }
      {/* {loading === false && <h1>Login</h1>} */}
      {/* {errors === true && <h2 id="prompt">{message}</h2> } */}
      {loading === false && (
        <form onSubmit={onSubmit}>
        <div className="mb-3">
        <label htmlFor="username" className="form-label">Username</label>
        <input 
            className="form-control" 
            name='username'
            type="username" 
            id="username"
            value={username}
            required
            onChange={e => setUsername(e.target.value)}
        /> {' '}
        </div>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">Password</label>
        {forgotPassword === true ? (
          <Fragment>
            <input 
                className="form-control" 
                name='password'
                type="password" 
                id="password" 
                value={password}
                // required
                onChange={e => setPassword(e.target.value)}
            /> {' '}
          </Fragment>
        ) : (
          <Fragment>
            <input 
                className="form-control" 
                name='password'
                type="password" 
                id="password" 
                value={password}
                required
                onChange={e => setPassword(e.target.value)}
            /> {' '}
          </Fragment>
        )}
        </div>
        {/* <div className="mb-3 form-check">
        <input type="checkbox" className="form-check-input" id="remember_me" />
        <label className="form-check-label" htmlFor="remember_me">Remember me</label>
        </div> */}
        <div className="mb-3 form-check">
        <input 
          className="form-check-input" 
          type="checkbox" 
          value={forgotPassword}
          id="forgot_password" 
          onChange={e => {
            setForgotPassword(e.target.checked);
            (e.target.checked === true) ? 
              (document.getElementById("submit_button").innerHTML = "Password Reset") :
              (document.getElementById("submit_button").innerHTML = "Login") 
            }
          }
        />
        <label className="form-check-label" htmlFor="forgot_password">Forgot Password?</label>
        </div>
        <button type="submit" className="btn btn-primary" id="submit_button">Login</button>
        </form>
      )}
    </div>
  );
};

export default Login;
import React, { useEffect, useState, Fragment } from "react";
// import "../App.css";
import { v4 as uuid } from "uuid";
import mqtt from "precompiled-mqtt";
const config = require('../../config');

var options = {
	protocol: "wss",
	username: config.mqtt_username,
	password: config.mqtt_password,
	keepalive: 30,
	reconnectPeriod: 3000,
	// clientId: "wss_" + Math.random().toString(16).slice(2, 10),
	// clientId: "mda_00",
};

console.log("reconnectPeriod", options.reconnectPeriod);

const Media = () => {
	// Sets default React state
	const [connectStatus, setConnectStatus] = useState('idle');
	const [client, setClient] = useState(null);
	const mqttConnect = (brokerUrl, options) => {
		const unique_id = uuid();
		const small_id = unique_id.slice(0, 8);
		console.log(unique_id);
		console.log(small_id);
		options.clientId = "wss_" + small_id;
		setConnectStatus('Connecting');
		setClient(mqtt.connect(brokerUrl, options));
	};
	const [topic, setTopic] = useState(
		<Fragment>
			<em>...</em>
		</Fragment>
	);
	const [message, setMessage] = useState(
		<Fragment>
			<em>...</em>
		</Fragment>
	);
	const [isSub, setIsSub] = useState(false);

	// const mqttDisconnect = () => {
	// 	if (client) {
	// 	  client.end(() => {
	// 		setConnectStatus('Disconnected');
	// 	  });
	// 	}
	// };

	useEffect(() => {
		if (client) {
			client.on('connect', () => {
				console.log("Connected successfully.");
				setConnectStatus('Connected');
				// console.log(connectStatus);
				client.subscribe('#');
				client.publish('/dync/projectors', 'getStatus');
			});
			client.on('error', (err) => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on("message", function (topic, message) {
				// console.log(connectStatus);
				console.log(topic.toString(), message.toString());

				var chan = topic.toString();
				var note = message.toString();

				// Updates React state with message
				setTopic(chan);
				console.log(chan);
				setMessage(note);
				console.log(note);
			});
			// client.on('disconnect', () => {
			// 	console.log("Received disconnect packet from broker. MQTT 5.0 Feature.");
			// 	// setConnectStatus('Reconnecting');
			// });
			client.on('offline', () => {
				console.log("Client goes offline.");
			});
			client.on('close', () => {
				console.log("Disconnected.");
			});
			client.on('reconnect', () => {
				console.log("Reconnect starts.");
			});
		}
		else {
	        // options.clientId = "media_" + Math.random().toString(16).slice(2, 10);
			// options.clientId = "media_00";
			console.log("CONNECTING AGAIN!!!");
			mqttConnect(config.mqtt_brokerUrl, options);
		}
	}, [client]);
	// }, [client, connectStatus]);

	return (
		<div className="Home">
		<header className="Home-header">
			<h1>Hello MQTT in React</h1>
			<p>The topic is: {topic}</p>
			<p>The message is: {message}</p>
			<a className="btn btn-primary"
				data-bs-toggle="collapse"
				href="#collapseExample"
				role="button"
				aria-expanded="false"
				aria-controls="collapseExample">
				Publish
			</a>
		</header>
		</div>
	);
}

export default Media;

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  console.log("dashboard: trace 0");

  useEffect(() => {
    console.log("dashboard: trace 1");
    if (localStorage.getItem('token') === null) {
      window.location.replace('https://dync.org/login');
    } 
    else {
      async function fetchUsername(token) {
          const res = await axios.post('https://dync.org/api/auth/verify', 
          {
              token
          });
          console.log(res);
          console.log(res.data);
          if (res.data.username.length > 0) {
              if (res.data.groups.length > 0) {
                  if (res.data.groups.includes("admin")) {
                      console.log("Admin user group.");
                      // setIsAdmin(true);
                      // setIsAuth(true);
                  }
              }
          }
          else {
              // setIsAuth(false);
              window.location.replace('https://dync.org/login');
          }
      }
      fetchUsername(localStorage.getItem('token'));
    }
  }, []);

  return (
    <div>
      {loading === false && (
        <Fragment>
          {/* <table className="table table-sm table-striped table-hover">
            <thead>
              <tr>
                {tableHeader.map((columnHeader, index) => (
                  <th scope="col" key={index}>{columnHeader}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <th scope="row">{index}</th>
                  {user.map((field, index) => (
                    <td key={index}>{field}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table> */}
        </Fragment>
      )}
    </div>
  );
};

export default Dashboard;
import React, { useState, useEffect } from 'react';

const PasswordChange = () => {
  const token = localStorage.getItem('token');

  console.log('token = [' + token + ']');
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace(process.env.REACT_APP_SERVER_URL + '/login');
    } 
    else {
      setLoading(false);
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      old_password: password,
      new_password1: password1,
      new_password2: password2,
    };

    fetch(process.env.REACT_APP_API_SERVER_URL + '/api/v1/users/auth/password/change/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Token ' + token
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setMessage(Object.values(data));
        setLoading(true);
      });
  };

  return (
    <div className="container-fluid login">
      {(message.length > 0) &&
        <div className="alert alert-success alert-dismissible" role="alert">
        {message}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      }
      {loading === false && (
        <form onSubmit={onSubmit}>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">Old Password</label>
          <input 
              className="form-control" 
              type="password" 
              name='password'
              id="password" 
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
          /> {' '}
        </div>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">New Password</label>
          <input 
              className="form-control" 
              type="password" 
              name='password1'
              id="password1" 
              value={password1}
              required
              onChange={e => setPassword1(e.target.value)}
          /> {' '}
        </div>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">Confirm Password</label>
          <input 
              className="form-control" 
              type="password" 
              name='password2'
              id="password2" 
              value={password2}
              required
              onChange={e => setPassword2(e.target.value)}
          /> {' '}
        </div>
        <button type="submit" className="btn btn-primary">Change Password</button>
        </form>
      )}
    </div>





  );
};

export default PasswordChange;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PasswordResetConfirm = () => {
  // let {uid, token} = useParams();
  const {uid, token} = useParams();
  console.log('uid = [' + uid + ']');
  console.log('token = [' + token + ']');
  // const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState(false);
  const [message, setMessage] = useState('Enter New Password');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace(process.env.REACT_APP_SERVER_URL + '/dashboard');
    } 
    else {
      setLoading(false);
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      // email: email,
      new_password1: password1,
      new_password2: password2,
      uid: uid,
      token: token
    };

    fetch(process.env.REACT_APP_API_SERVER_URL + '/password-reset-confirm/' + uid + '/' + token + '/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        // if (data.detail) {
        //   setMessage(data.detail);
        // }
        if (data.key) {
          console.log("TRACE 333");
          localStorage.clear();
          localStorage.setItem('token', data.key);
          window.location.replace(process.env.REACT_APP_SERVER_URL + '/dashboard');
        } 
        else {
          console.log("TRACE 334");
          // setEmail('');
          setPassword1('');
          setPassword2('');
          localStorage.clear();
          setMessage(Object.values(data));
          console.log(message);
          setErrors(true);
          setLoading(true);
          // window.location.replace(process.env.REACT_APP_SERVER_URL + '/login');
        }
        console.log("TRACE 335");
      });
  };

  return (
    <div className="container-fluid login">
      {(message.length > 0 || errors === true) &&
        <div className="alert alert-success alert-dismissible" role="alert">
        {message}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      }
      {loading === false && (
        <form onSubmit={onSubmit}>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">New Password</label>
          <input 
              className="form-control" 
              type="password" 
              name='password1'
              id="password1" 
              value={password1}
              required
              onChange={e => setPassword1(e.target.value)}
          /> {' '}
        </div>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">Confirm Password</label>
          <input 
              className="form-control" 
              type="password" 
              name='password2'
              id="password2" 
              value={password2}
              required
              onChange={e => setPassword2(e.target.value)}
          /> {' '}
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      )}
    </div>
  );
};

export default PasswordResetConfirm;
// import React from 'react';
import React, { useState, useEffect, useRef, memo, Fragment } from 'react';

const Updates = ({ updates }) => (
  // <h4 className="update">Paints<br />{updates}</h4>
  <h4 className="update">Paints:{updates}</h4>
);

const Child = ({name}) => {
  const updates0 = useRef(0);
  const updates1 = useRef(0);
  const updates2 = useRef(0);
  const [updated, setUpdated] = useState(updates1.current);

  useEffect(() => {
    updates0.current++;
    console.log("updates0:" + updates0.current);
  }, []);

  useEffect(() => {
    updates2.current++;
    console.log("updates2:" + updates2.current);
  }, [updated]);

  useEffect(() => {
    // componentDidUpdate
    updates1.current++;
    // console.log("childComponentDidUpdate: runs after every render:" + updates1.current);
    console.log("updates1:" + updates1.current);
  });

  const handleClick = () => {
    console.log("Before setting 'updated'");
    console.log(">>> updates2:" + updates2.current);
    // setUpdated(updates1.current);
    setUpdated(updates0.current);
    console.log(">>> updates2:" + updates2.current);
    console.log("After setting 'updated'");
  };

  return (
    <div>
      <button type="button" 
              className="btn btn-outline-primary" 
              onClick={() => handleClick()}>
                {updates0.current}
                &nbsp;
                {updates1.current}
                &nbsp;
                {updates2.current}
      </button>
    </div>
  );
}

const Tile = ({ children }) => {
  let eventUpdates = useRef(0);
  return (
    <div className="black-tile">
      No memo
      <Updates updates={eventUpdates.current++} />
      {children}
    </div>
  );
}

const TileMemo = memo(({ children }) => {
  let updates = useRef(0);
  return (
    <div className="black-tile">
      Memo
      <Updates updates={updates.current++} />
      {children}
    </div>
  );
}, (prevProps, nextProps) => {
  // evaluate whether the props have changed and if
  // the component should update
  return true;
});

const Practice = () => {
  const updates = useRef(0);
  const didUpdates = useRef(0);
  const [text, setText] = useState('');

  useEffect(() => {
    // componentDidUpdate
    didUpdates.current++;
    console.log("componentDidUpdate: runs after every render:" + didUpdates.current + ", " + updates.current);
  });

  useEffect(() => {
    // componentDidMount
    console.log("componentDidMount: runs after mounted (after first render only).");
    return () => {
      // componentWillUnmount
      console.log("componentWillUnmount:");
    };
  }, []);

  const handleChange = event => {
    console.log("handleChange:" + didUpdates.current + ", " + updates.current);
    event.preventDefault();
    // setText(event.target.value);
    setText("Hello");
  };

  return (
    // <div className="app">
    <div>
      <Child />
    </div>
  );
};

export default Practice;
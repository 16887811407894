// import React from "react";
import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Login from './views/auth/Login';           // new 
import Logout from './views/auth/Logout';         // new 
import Dashboard from './views/app/Dashboard';    // new
import Media from './views/app/Media';    // new
import Screen from './views/app/Screen';    // new
import Signup from './views/auth/Signup';         // new 
import PasswordResetConfirm from './views/auth/PasswordResetConfirm';         // new 
import PasswordChange from './views/auth/PasswordChange';         // new 
// import Members from './views/app/Members';    // new
// import Offering from './views/app/Offering';    // new
// import Promises from './views/app/Promises';    // new
import Practice from './views/app/Practice';    // new
 
const App = () => {
  console.log("BEGINNING ...")
  const [isAuth, setIsAuth] = useState(false);
  const [devices, setDevices] = useState([]);
  // const [headerHeightFixed, setHeaderHeightFixed] = useState(false);

  // useEffect(() => {
  //   if (headerHeightFixed === false) {
  //     const elem = document.getElementById('header_element');
  //     if (elem !== null) {
  //       const h = elem.clientHeight;
  //       console.log("header height = " + h);
  //       // setHeaderHeight(h);
  //       setHeaderHeightFixed(true);
  //     }
  //   }
  // }, [headerHeightFixed]);

  var searchEventHandler = (searchText) => {};
  var createEventHandler = () => {};
  const onNavbarSearchSubmit = (searchText, endOfText) => {
    if (searchEventHandler!==null) {
      searchEventHandler(searchText, endOfText);
    }
  }

  const onNavbarCreate = () => {
    console.log("onNavbarCreate:");
    if (createEventHandler!==null) {
      createEventHandler();
    }
  }

  const addSearchEventHandler = (handler) => {
    searchEventHandler = handler;
  }

  const addCreateEventHandler = (handler) => {
    createEventHandler = handler;
  }

  return (
    <div className='App'>
      <div className="container-fluid h-100 d-flex flex-column">
        <div className="row flex-fill" style={{height: "100vh"}}>
          <Router>
            <Navbar onNavbarSearchSubmit={onNavbarSearchSubmit} 
                    onNavbarCreate={onNavbarCreate}
                    setIsAuth = {setIsAuth}
                    setDevices = {setDevices}
            />
            <Routes>
              <Route path='/login' element={<Login />} />
              {isAuth && (
                <Fragment>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/media' element={<Media />} />
                    <Route path='/screen' element={<Screen devices = {devices} />} />
                </Fragment>
              )}
              <Route path='/practice' component={Practice} exact />
              <Route path='/signup' component={Signup} exact />
              <Route path='/password-reset-confirm/:uid/:token' component={PasswordResetConfirm} exact />
              <Route path='/change-password' component={PasswordChange} exact />
              {/* <Route path='/members'>
                <Members addSearchEventHandler={addSearchEventHandler} addCreateEventHandler={addCreateEventHandler} />
              </Route>
              <Route path='/offering'>
                <Offering addSearchEventHandler={addSearchEventHandler} addCreateEventHandler={addCreateEventHandler}/>
              </Route> */}
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default App;

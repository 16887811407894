import React, { useEffect, useState, Fragment } from "react";
// import "./Screen.css";
import mqtt from "precompiled-mqtt";
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';
const config = require('../../config');

var options = {
	protocol: "wss",
	username: config.mqtt_username,
	password: config.mqtt_password,
	keepalive: 30,
	clientId: "dyncmx_" + Math.random().toString(16).slice(2, 10),
};

// console.log("config.broker url = " + config.mqtt_brokerUrl);
// console.log("clientId = " + options.clientId);
// console.log("config.username = " + config.mqtt_username);
// console.log("config.password = " + config.mqtt_password);

var timers = null;
var timerMqtt = -1;

const Screen = (props) => {
	// Sets default React state
	const [connectStatus, setConnectStatus] = useState('idle');
	const [client, setClient] = useState(null);
	const [loading, setLoading] = useState(true);
	const [screenDisabled, setScreenDisabled] = useState([true]);
	const [showSpinner, setShowSpinner] = useState([false]);
	const [screenTurnedOn, setScreenTurnedOn] = useState([false]);
	const [modalDeviceIndex, setModalDeviceIndex] = useState(0);
	const [modalDeviceName, setModalDeviceName] = useState("");
	const [spinnerColor, setSpinnerColor] = useState([]);
	const [buttonColor, setButtonColor] = useState([]);
	const [deviceStatus, setDeviceStatus] = useState([]);
	const [modalTitle, setModalTitle] = useState("");
	const [modalBody, setModalBody] = useState("");
	const [modalButton, setModalButton] = useState("OK");
	const [inService, setInService] = useState([]);

	useEffect(() => {
    	console.log("Props - ", props);
		console.log(props.devices.length);
		if (props.devices.length > 0) {
			setSpinnerColor(Array(props.devices.length).fill("spinner-border text-primary"));
			setButtonColor(Array(props.devices.length).fill("btn btn-outline-primary"));
			setDeviceStatus(Array(props.devices.length).fill(""));
			setShowSpinner(Array(props.devices.length).fill(false));
			setScreenDisabled(Array(props.devices.length).fill(false));
			setScreenTurnedOn(Array(props.devices.length).fill(false));
			setInService(Array(props.devices.length).fill(true));
			timers = Array(props.devices.length).fill(-1);
		}
	}, [props]);

	useEffect(() => {
		// console.log(localStorage.getItem('token'));
	}, []);

	const mqttConnect = (brokerUrl, options) => {
		setConnectStatus('Connecting');
		setClient(mqtt.connect(brokerUrl, options));
	};

	useEffect(() => {
		if (client) {
			client.on('connect', () => {
				if (timerMqtt > 0) {
					// MQTT connected. Cancel timerMqtt.
					clearTimeout(timerMqtt);
					timerMqtt = -1;
				}
				setConnectStatus('Connected');
				props.devices.forEach((device, index) => {
					console.log(device.topicSub, device.topicPub);
					client.subscribe(device.topicSub);
					client.publish(device.topicPub, JSON.stringify({'uart_tx': device.uartTxReadPowerMode}));
					setSpinnerColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"spinner-grow text-info":s})});
					setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? true: s})});
					setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"Checking power mode ...":s})});
					setLoading(false);
					timers[index] = setTimeout((name, device) => {
						timers[index] = -1;
						setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"Check Power Mode":s})});
						setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
						setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index)?false:s})});
					}, 30000, "T101", device);
				});
			});
			client.on('error', (err) => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on('reconnect', () => {
				setConnectStatus('Reconnecting');
			});
			client.on("message", function (topic, message) {
				topic = topic.toString();
				message = JSON.parse(message);
				console.log(screenTurnedOn, topic, message);
				props.devices.forEach((device, index) => {
					if (topic === device.topicSub) {
						// setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index)?true:s})});
						if (Object.hasOwn(message, 'uart_rx')) {
							setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index)?true:s})});
							if (message.uart_rx === device.uartRxIndWarmingUp.code) {
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?device.uartRxIndWarmingUp.description:s})});
								setSpinnerColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"spinner-border text-primary":s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? true: s})});
							}
							if (message.uart_rx === device.uartRxIndCoolingDown.code) {
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?device.uartRxIndCoolingDown.description:s})});
								setSpinnerColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"spinner-border text-danger":s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? true: s})});
							}
							if (message.uart_rx === device.uartRxIndPowerOff.code || message.uart_rx === device.uartRxIndStandbyMode.code) {
								if (timers[index] > 0) {
									clearTimeout(timers[index]);
									timers[index] = -1;
								}
								setScreenTurnedOn((prevState)=>{return prevState.map((s,i)=>{return (i===index)?false:s})});
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?device.uartRxIndStandbyMode.description:s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
								setLoading(false);
							}
							else if (message.uart_rx === device.uartRxIndPowerOn.code || message.uart_rx === device.uartRxIndSystemReady.code) {
								if (timers[index] > 0) {
									clearTimeout(timers[index]);
									timers[index] = -1;
								}
								setScreenTurnedOn((prevState)=>{return prevState.map((s,i)=>{return (i===index)?true:s})});
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?device.uartRxIndSystemReady.description:s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
								setLoading(false);
							}
						}
						if (Object.hasOwn(message, 'peer_rx')) {
							setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index)?true:s})});
							if (message.peer_rx === "ON_NC") {
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? "Check Power Mode": s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
								setButtonColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)? "btn btn-outline-primary": s})});
								setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
							}
							else if (message.peer_rx === "OFF_NC") {
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? "Check Power Mode": s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
								setButtonColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)? "btn btn-outline-secondary": s})});
								setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
							}
							else if (message.peer_rx === "Power On Initiated") {
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)? "Turning On ...": s})});
								setSpinnerColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)? "spinner-border text-primary": s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? true: s})});
							}
							else if (message.peer_rx === "Power Off Initiated") {
								setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)? "Turning Off ...": s})});
								setSpinnerColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)? "spinner-border text-danger": s})});
								setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? true: s})});
							}
						}
					}
				});
			});
		}
		else {
	        options.clientId = "dyncmx_" + Math.random().toString(16).slice(2, 10);
			mqttConnect(config.mqtt_brokerUrl, options);
			timerMqtt = setTimeout((name) => {
				// MQTT Server is not responding
				timerMqtt = -1;
				setModalTitle("NO SERVICE");
				setModalBody("Error Code:100. Try Later!");
				setModalButton("Dismiss");
				let modal = new Modal(document.getElementById('staticBackdropNotify'));
				modal.show();
			}, 30000, "T100");
		}
	}, [client]);

	const handleCheckPowerMode = (e, index) => {
		e.preventDefault();
		// Check Power Mode
		setSpinnerColor((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"spinner-grow text-info":s})});
		setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? true: s})});
		setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index)?true:s})});
		client.publish(props.devices[index].topicPub, JSON.stringify({'uart_tx': props.devices[index].uartTxReadPowerMode}));
		setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"Checking power mode ...":s})});
		timers[index] = setTimeout((name, device) => {
			timers[index] = -1;
			setShowSpinner((prevState)=>{return prevState.map((s,i)=>{return (i===index) ? false: s})});
			// setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"No Response":s})});
			setDeviceStatus((prevState)=>{return prevState.map((s,i)=>{return (i===index)?"Check Power Mode":s})});
			setInService((prevState)=>{return prevState.map((s,i)=>{return (i===index)?false:s})});
		}, 30000, "T101", props.devices[index]);
	};

	const onChange = (e, index) => {
		if (screenTurnedOn[index] === false) {
			if (e.target.checked === true) {
				// Turn on the screen
				client.publish(props.devices[index].topicPub, JSON.stringify({'uart_tx': props.devices[index].uartTxPowerOn}));
				client.publish(props.devices[index].topicSub, JSON.stringify({'peer_rx': "Power On Initiated"}));
				timers[index] = setTimeout((name, device) => {
					timers[index] = -1;
					client.publish(device.topicSub, JSON.stringify({'peer_rx': "ON_NC"}));
				}, 60000, "T102", props.devices[index]);
			}
		}
		else {
			if (e.target.checked === false) {
				// Turn off the screen
				setModalDeviceIndex(index);
				setModalDeviceName(props.devices[index].name);
				let modal = new Modal(document.getElementById('staticBackdropTurnoff'));
				modal.show();
			}
		}
	};

	const handleTurnOff = (e, modalIndex) => {
		const index = modalIndex.modalDeviceIndex;
		e.preventDefault();

		// Turn off the projector
		client.publish(props.devices[index].topicPub, JSON.stringify({'uart_tx': props.devices[index].uartTxPowerOff}));
		client.publish(props.devices[index].topicSub, JSON.stringify({'peer_rx': "Power Off Initiated"}));
		timers[index] = setTimeout((name, device) => {
			timers[index] = -1;
			client.publish(device.topicSub, JSON.stringify({'peer_rx': "OFF_NC"}));
		}, 40000, "T103", props.devices[index]);
	};

	const switchStyle = {
		marginTop: "1rem",
		marginLeft: "0.2rem",
		width: "2.5rem",
		height: "1.5rem"
	};

	const switchLabelStyle = {
		marginTop: "1rem",
		marginLeft: "1rem",
		height: "2rem"
	};

	const spinnerStyle = {
		marginLeft: "1.0rem",
		width: "1.5rem",
		height: "1.5rem"
	};

	return (
		<div className="row flex-fill" id="body_element_block" style={{height: "100%"}}>
			{loading === false &&
				<Fragment>
					<div className="container text-left">
						<div className="row align-items-start">
							{props.devices.map((device, i) => (
								<div className="row" key={i}>
									<label className="form-check-label" style={switchLabelStyle} ><strong>{device.name}</strong></label>
									<div className="form-check form-switch">
                                    	{showSpinner[i] === true ? (
											<Fragment>
												<div className={spinnerColor[i]} role="status" style={spinnerStyle}>
													<span className="visually-hidden">Loading...</span>
												</div>
												<label className="form-check-label" htmlFor={`flexSwitchScreen-${i}`} style={switchLabelStyle} >{deviceStatus[i]}</label>
											</Fragment>
										):(
											<Fragment>
												{inService[i] === true ? (
													<Fragment>
														<input className="form-check-input" type="checkbox" role="switch" id = {`flexSwitchScreen-${i}`}
															style={switchStyle} checked={screenTurnedOn[i]} disabled={screenDisabled[i]} onChange={e => onChange(e, i)} />
														<label className="form-check-label" htmlFor={`flexSwitchScreen-${i}`} style={switchLabelStyle} >{deviceStatus[i]}</label>
													</Fragment>
												):(
													<Fragment>
														<button type="button" className={buttonColor[i]} onClick={e => handleCheckPowerMode(e, i)}>{deviceStatus[i]}</button>
													</Fragment>
												)}
											</Fragment>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				</Fragment>
			}
			<div className="modal fade" id="staticBackdropTurnoff" data-bs-backdrop="static" data-bs-keyboard="false" 
				tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="staticBackdropLabel">Confirmation</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						Turn {modalDeviceName} Off?
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-danger" data-bs-dismiss="modal" 
							onClick={e => handleTurnOff(e, {modalDeviceIndex})}>Turn Off</button>
					</div>
					</div>
				</div>
			</div>
			<div className="modal fade" id="staticBackdropNotify" data-bs-backdrop="static" data-bs-keyboard="false" 
				tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="staticBackdropLabel">{modalTitle}</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{modalBody}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{modalButton}</button>
					</div>
					</div>
				</div>
			</div>
		 </div>
	);
}

export default Screen;

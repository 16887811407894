// import React, { useState, useEffect, Fragment, Component } from 'react';
import React, { Fragment, Component } from 'react';

// const Logout = () => {
class Logout extends Component  {
  constructor(props) {
    console.log("Props - ", props);
    super(props);
    this.loading = false;
    // [this.loading, this.setLoading] = useState(true);
  }

  componentDidMount() {
    console.log("componentDidMount");
    // const myModal = document.getElementById('staticBackdrop');
    // this.setState({showModal: true});
    if (localStorage.getItem('token') === null) {
      window.location.replace(process.env.REACT_APP_SERVER_URL + '/login');
    } 
    else {
      this.loading = false;
      // setLoading(false);
      // this.props.loading = false;
      // this.logoutModal = document.getElementById('staticBackdrop');
      // this.logoutModal.modal('show');
      // document.getElementById('staticBackdrop').show;
    }
  }

  handleLogout = e => {
    console.log("handleLogout");
    e.preventDefault();
    fetch(process.env.REACT_APP_API_SERVER_URL + '/api/v1/users/auth/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        localStorage.clear();
        window.location.replace(process.env.REACT_APP_SERVER_URL + '/login');
      });
  };

  render() {
    return (
      // <div>
      //   {this.loading === false && (
      //     <Fragment>
      //       <h1>Are you sure you want to logout?</h1>
      //       <input type='button' value='Logout' onClick={this.handleLogout} />
      //     </Fragment>
      //   )}
      // </div>

      <div>
          <Fragment>
            <h1>Are you sure you want to logout?</h1>
            {/* <a data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Launch Modal</a> */}
            {/* <input type='button' value='Logout' onClick={this.handleLogout} /> */}
            <input type='button' value='Logout' data-bs-toggle="modal" data-bs-target="#staticBackdrop"/>
          </Fragment>
          <Fragment>
          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
              Launch static backdrop modal
          </button>
          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      Modal content...
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary">Understood</button>
                  </div>
                </div>
            </div>
          </div>
          </Fragment>
      </div>
    );
  }

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (localStorage.getItem('token') === null) {
  //     window.location.replace(process.env.REACT_APP_SERVER_URL + '/login');
  //   } 
  //   else {
  //     setLoading(false);
  //     // let logoutModal = document.getElementById('staticBackdrop');
  //     // logoutModal.modal('show');
  //   }
  // }, []);

  // const handleLogout = e => {
  //   e.preventDefault();

  //   fetch(process.env.REACT_APP_API_SERVER_URL + '/api/v1/users/auth/logout/', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Token ${localStorage.getItem('token')}`
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data);
  //       localStorage.clear();
  //       window.location.replace(process.env.REACT_APP_SERVER_URL + '/login');
  //     });
  // };

  // return (
  //   <div>
  //     {loading === false && (
  //       <Fragment>
  //         <h1>Are you sure you want to logout?</h1>
  //         <input type='button' value='Logout' onClick={handleLogout} />
  //       </Fragment>
  //     )}
  //   </div>


    // <div>
    // <div className="container-fluid">
    //   {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    //       Launch static backdrop modal
    //   </button> */}
    //   {loading === false && (
    //     // <Fragment>
    //       // <h1>Are you sure you want to logout?</h1>
    //       <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    //         <div className="modal-dialog">
    //             <div className="modal-content">
    //               <div className="modal-header">
    //                   <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
    //                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    //               </div>
    //               <div className="modal-body">
    //                   Modal content...
    //               </div>
    //               <div className="modal-footer">
    //                   <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    //                   <button type="button" className="btn btn-primary">Understood</button>
    //               </div>
    //             </div>
    //         </div>
    //       </div>
    //     // </Fragment>
    //   )}
    // </div>




  // );
};

export default Logout;
import React, { useState, useEffect } from 'react';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace(process.env.REACT_APP_SERVER_URL + '/dashboard');
    } 
    else {
      setLoading(false);
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      email: email,
      password1: password1,
      password2: password2
    };

    fetch(process.env.REACT_APP_API_SERVER_URL + '/api/v1/users/auth/register/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        if (data.key) {
          localStorage.clear();
          localStorage.setItem('token', data.key);
          window.location.replace(process.env.REACT_APP_SERVER_URL + '/dashboard');
        } 
        else {
          setEmail('');
          setPassword1('');
          setPassword2('');
          localStorage.clear();
          setMessage(Object.values(data));
          setLoading(true);
          setErrors(true);
        }
      });
  };

  return (
    // <div className="container-fluid login">
    <div className="container-fluid">
      {(message.length > 0 || errors === true) &&
        <div className="alert alert-success alert-dismissible" role="alert">
        {message}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      }
      {loading === false && (
        <form onSubmit={onSubmit}>
        <div className="mb-3">
        <label htmlFor="email" className="form-label">Email address</label>
        <input 
            className="form-control" 
            name='email'
            type="email" 
            id="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
        /> {' '}
        </div>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">Password</label>
        <input 
            className="form-control" 
            name='password1'
            type="password" 
            id="password1" 
            value={password1}
            required
            onChange={e => setPassword1(e.target.value)}
        /> {' '}
        </div>
        <div className="mb-3">
        <label htmlFor="password" className="form-label">Confirm Password</label>
        <input 
            className="form-control" 
            name='password2'
            type="password" 
            id="password2"
            value={password2}
            required
            onChange={e => setPassword2(e.target.value)}
        /> {' '}
        </div>
        <button type="submit" className="btn btn-primary" id="submit_button">Sign Up</button>
        </form>
      )}
    </div>
  );
};

export default Signup;